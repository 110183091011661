import React from "react"
import styled from "styled-components"

import { Link, graphql, useStaticQuery } from "gatsby"

function Layout (props) {
  const { location, children } = props;
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "AlyssaAtain_UXResearcher.pdf"}) {
        publicURL
      }
    }
  `)
  const header = (
    <>
      <TitleWrapper>
        <Title>Alyssa Atain</Title>
        <Text
          style={{
            marginBottom: '10px',
          }}
        >
          User Advocate | Inquisitive Researcher | Big-Picture Thinker
        </Text>
      </TitleWrapper>
      <NavWrapper>
        <StyledLink
          decoration={location.pathname === '/'}
          to={`/`}
        >
          ABOUT
        </StyledLink>
        <StyledLink
          decoration= {location.pathname === '/portfolio/'}
          to={`/portfolio/`}
        >
          PORTFOLIO
        </StyledLink>
        <StyledResumeLink
          href={data.file.publicURL}
          target="_blank"
        >
          RESUME
        </StyledResumeLink>
        {/* <StyledLink
          decoration= {location.pathname === '/writings'}
          to={`/writings`}
        >
          WRITING
        </StyledLink> */}
      </NavWrapper>
    </>
  );

  return (
    <Wrapper>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
        }}
      >
        <StyledHeader>{header}</StyledHeader>
        <main style={{
          margin: '0 auto',
        }}>{children}</main>
      </div>
      <Footer>
        Website design and content
        © {new Date().getFullYear()}
        {` `}
        Alyssa Atain.
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const StyledHeader = styled.header`
  padding: 1.992rem 1.992rem 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  flex: 1 0 100%;
  text-align: center;
  font-size: 20px;

  @media (min-width: 1100px) {
    flex-basis: 500px;
    text-align: left;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 700;
  font-size: 2.83rem;
  line-height: 2.9rem;
  text-transform: uppercase;
  margin: 0 0 10px 0;

  @media (min-width: 1100px) {
    margin-bottom: 0;
  }
`;

const Text = styled.p`
  font-size: 16px;  

  @media (min-width: 1100px) {
    font-size: 14px;
  }
`;

const NavWrapper = styled.nav`
  flex: 1 0 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1100px) {
    flex-basis: 300px;
    text-align: right;
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  box-shadow: none;
  color: ${props => props.decoration ? `#f9a83d` : 'inherit'};
  text-decoration: ${props => props.decoration ? `underline` : 'inherit'};
  font-size: 20px;
  margin-right: 15px;

  :hover {
    color: #f9a83d;
  }
`;

const StyledResumeLink = styled.a`
  font-weight: 600;
  box-shadow: none;
  color: ${props => props.decoration ? `#f9a83d` : 'inherit'};
  text-decoration: ${props => props.decoration ? `underline` : 'inherit'};
  font-size: 20px;
  cursor: pointer;

  :hover {
    color: #f9a83d;
  }
`;

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
  font-size: 12px;
  background-color: #7d7d7d;
  color: white;
  width: 100%;
  margin: 0;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
`

export default Layout
